* {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
    order: 0;
}
body {
    background-color: rgb(223, 223, 223);
}

h1 {
    text-align: center;
    margin: 0px;
    margin-bottom: 20px;
}

/* .menuButton {
    display: block;
}

.menuButton:hover {
    background-color: rgb(86, 85, 85);
} */

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.container-1,
.container-2 {
    display: flex;
    flex-direction: column;
    margin: 50px;
}

.container-muokkaaKategoria {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.muiTextField {
    margin-bottom: 20px;
}
.select {
    /* margin-top: 10px; */
    margin-bottom: 30px;
    display: flex;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 0px 1px 1px 0px rgb(90, 90, 90);
}

.object {
    margin: 5px;
    padding: 10px;
    min-width: 300px;
    max-width: 300px;
    height: auto;
    overflow: auto;
}

.id {
    margin: 5px;
    padding: 10px;
    min-width: 50px;
    max-width: 50px;
    overflow: auto;
}

.tehtava {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    font-size: 20px;
}

.tehtava:hover .tehtavaNappi {
    display: block;
}

/* .tehtavaNappi {
    display: none;
    margin: 5px;
    padding: 10px;
    min-width: 50px;
    max-width: 50px;
    overflow: auto;
} */

.tehtavakortti-arvo,
.tehtavakortti-otsikko {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
    margin: 0px 10px 10px 0px;
}

.kategoriat {
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin-bottom: 0px;
    font-size: 20px;
}
.kategoriat-lista {
    overflow: auto;
    height: auto;
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.muiCheckboxGroup {
    margin: 10px;
    display: flex;
    flex-direction: column;
    height: 200px;
    width: 180px;
    overflow: auto;
    align-items: center;
    justify-content: center;
}

.tehtavakortti::-webkit-scrollbar {
    width: 8px;
    height: auto;
}

.tehtavakortti::-webkit-scrollbar-thumb {
    background: rgb(114, 114, 114);
    border-radius: 10px;
}

.timeTaskCard,
.muiCheckboxGroup::-webkit-scrollbar {
    width: 8px;
    height: auto;
}

.timeTaskCard,
.muiCheckboxGroup::-webkit-scrollbar-thumb {
    background: rgb(114, 114, 114);
    border-radius: 10px;
}

.editView {
    background-color: white;
    box-shadow: 0px 5px 5px;
    width: 400px;
    height: auto;
}
